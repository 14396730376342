import { default as _91_46_46_46slug_93bih89tyz1MMeta } from "/codebuild/output/src1217916800/src/pages/[mainSchema]/[...slug].vue?macro=true";
import { default as _91childSchema_93Kk7kfD6llsMeta } from "/codebuild/output/src1217916800/src/pages/[mainSchema]/[childSchema].vue?macro=true";
import { default as indexcPiidSxQR0Meta } from "/codebuild/output/src1217916800/src/pages/[mainSchema]/index.vue?macro=true";
import { default as _91_46_46_46slug_93woKVtKwBU9Meta } from "/codebuild/output/src1217916800/src/pages/character/[...slug].vue?macro=true";
import { default as indexB8JDOG1yX3Meta } from "/codebuild/output/src1217916800/src/pages/character/index.vue?macro=true";
import { default as _91_46_46_46slug_93PYgdEAreM1Meta } from "/codebuild/output/src1217916800/src/pages/dataType/[...slug].vue?macro=true";
import { default as _91childDataType_93afLTyMkineMeta } from "/codebuild/output/src1217916800/src/pages/dataType/[childDataType].vue?macro=true";
import { default as indexkScQs9w2ImMeta } from "/codebuild/output/src1217916800/src/pages/dataType/index.vue?macro=true";
import { default as _91_46_46_46slug_93Lf2Ym4gNDlMeta } from "/codebuild/output/src1217916800/src/pages/docs/[...slug].vue?macro=true";
import { default as indexvHZt2bHlxhMeta } from "/codebuild/output/src1217916800/src/pages/docs/index.vue?macro=true";
import { default as indexk93QbH5IOkMeta } from "/codebuild/output/src1217916800/src/pages/index.vue?macro=true";
import { default as message_45board_46clientv0xrT25neyMeta } from "/codebuild/output/src1217916800/src/pages/message-board.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/codebuild/output/src1217916800/src/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_eslint@8.57.0_typescript@5.5.3_vite@5.3.2/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91_46_46_46slug_9335tkbUiMRSMeta } from "/codebuild/output/src1217916800/src/pages/prefix/[...slug].vue?macro=true";
import { default as indexR3JhVLk0arMeta } from "/codebuild/output/src1217916800/src/pages/prefix/index.vue?macro=true";
import { default as _91_46_46_46slug_93GsEkIC79KVMeta } from "/codebuild/output/src1217916800/src/pages/suffix/[...slug].vue?macro=true";
import { default as indexn3VVnr2hNoMeta } from "/codebuild/output/src1217916800/src/pages/suffix/index.vue?macro=true";
export default [
  {
    name: "mainSchema-slug",
    path: "/:mainSchema()/:slug(.*)*",
    component: () => import("/codebuild/output/src1217916800/src/pages/[mainSchema]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "mainSchema-childSchema",
    path: "/:mainSchema()/:childSchema()",
    component: () => import("/codebuild/output/src1217916800/src/pages/[mainSchema]/[childSchema].vue").then(m => m.default || m)
  },
  {
    name: "mainSchema",
    path: "/:mainSchema()",
    component: () => import("/codebuild/output/src1217916800/src/pages/[mainSchema]/index.vue").then(m => m.default || m)
  },
  {
    name: "character-slug",
    path: "/character/:slug(.*)*",
    component: () => import("/codebuild/output/src1217916800/src/pages/character/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "character",
    path: "/character",
    component: () => import("/codebuild/output/src1217916800/src/pages/character/index.vue").then(m => m.default || m)
  },
  {
    name: "dataType-slug",
    path: "/dataType/:slug(.*)*",
    component: () => import("/codebuild/output/src1217916800/src/pages/dataType/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "dataType-childDataType",
    path: "/dataType/:childDataType()",
    component: () => import("/codebuild/output/src1217916800/src/pages/dataType/[childDataType].vue").then(m => m.default || m)
  },
  {
    name: "dataType",
    path: "/dataType",
    component: () => import("/codebuild/output/src1217916800/src/pages/dataType/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    component: () => import("/codebuild/output/src1217916800/src/pages/docs/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/codebuild/output/src1217916800/src/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1217916800/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "message-board",
    path: "/message-board",
    meta: message_45board_46clientv0xrT25neyMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src1217916800/src/pages/message-board.client.vue").then(m => m.default || m))
  },
  {
    name: "prefix-slug",
    path: "/prefix/:slug(.*)*",
    component: () => import("/codebuild/output/src1217916800/src/pages/prefix/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "prefix",
    path: "/prefix",
    component: () => import("/codebuild/output/src1217916800/src/pages/prefix/index.vue").then(m => m.default || m)
  },
  {
    name: "suffix-slug",
    path: "/suffix/:slug(.*)*",
    component: () => import("/codebuild/output/src1217916800/src/pages/suffix/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "suffix",
    path: "/suffix",
    component: () => import("/codebuild/output/src1217916800/src/pages/suffix/index.vue").then(m => m.default || m)
  }
]